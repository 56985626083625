import React from 'react';
import Style from './Home.module.scss';
import me from '../../img/cv-photo-2.png';
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";

const bioBullets = [
    {
        link: null,
        emoji: "🏙️",
        text: "Based in Cairo, Egypt"
    },
    {
        link: null,
        emoji: "💻",
        text: "Senior Software Engineer at Rasan Global"
    },
    {
        link: null,
        emoji: "☎️",
        text: "+2 012 77 041 971"
    },
    {
        link: "mailto:bony.nagy@gmail.com?subject=Let's work on something together!",
        emoji: "✉️",
        text: "bony.nagy@gmail.com"
    },
    {
        link: "https://drive.google.com/file/d/1R7S3vum7c5pFQOFbmq5nkrILSq35NcNN/view",
        emoji: "📝",
        text: "Download Résumé"
    }
];

const socials = [
    {
        link: "https://leetcode.com/abanoubmesiha/",
    },
    {
        link: "https://github.com/abanoubmesiha/",
        icon: "fa fa-github"
    },
    {
        link: "https://www.linkedin.com/in/abanoub-ayoub-025890b7/",
        icon: "fa fa-linkedin"
    },
    {
        link: "Stackoverflow.com/users/17148198/abanoub-ayoub/",
        icon: "fa fa-stack-overflow",
    },
    // {
    //     link: "https://www.facebook.com/Bony.mese7a/",
    //     icon: "fa fa-facebook"
    // },
]

export default function Home() {

    return (
        <main>
            <img src={me} alt="self" className={classNames(Style.avatar, Style.shadowed)}/>
            <div>
                <h1>Hi, I'm <span className={Style.firstName}>Abanoub</span> <span className={Style.hand}>👋🏻</span></h1>
                <h2>Senior Full-Stack Web Developer.</h2>
                <ul>
                    {bioBullets.map(bio => <EmojiBullet link={bio.link} emoji={bio.emoji} text={bio.text} />)}
                </ul>
                <div className={Style.socials}>
                    {socials.map(social => <SocialIcon link={social.link} icon={social.icon} />)}
                </div>
            </div>
        </main>
    )
}