import React from 'react';
import Style from './Experience.module.scss';
import Terminal from "./Terminal";

const projects = [
    {
        link: 'https://blinklos.com/',
        linkText: 'Blinklos.com',
        desc: 'Loan Origination System',
        location: 'United States'
    },
    {
        link: 'https://hemma.sa/',
        linkText: 'Hemma.sa',
        desc: 'Educational System',
        location: 'Saudi Arabia'
    },
    {
        link: 'http://www.dhsarabia.com/',
        linkText: 'DHSArabia.com',
        desc: 'Medical Claims Conveyance',
        location: 'Saudi Arabia'
    },
    {
        link: 'https://gokwaz.com/',
        linkText: 'Gokwaz.com',
        desc: 'Delivery Management System',
        location: 'Jordan'
    },
    {
        link: 'https://makanat.com/',
        linkText: 'Makanat.com',
        desc: 'Space booking Management',
        location: 'Dubai'
    },
];

export default function Experience() {

    function rasanText() {
        return <>
            <p><span className={Style.pink}>abanoub $</span> cd experience/rasan</p>
            <p><span className={Style.pink}>experience/rasan <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <p>
                <a
                    href="https://rasan.co/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Rasan Global
                </a>
            </p>
            <p>
                Senior frontend web developer
            </p>
            <p>
                03/2023 – now
            </p>
            <p className={Style.pink}> Managed to</p>
            <p>
            The onboarding was a little revamp and maintaining the First insurance platform in KSA, <a
                    href="http://tameeni.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Tameeni
                </a>, using React.js with Typescript.
            </p>
            <p>
            Revamp and maintain an auction website serving the gulf area <a
                    href="http://AwalMazad.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    AwalMazad
                </a>, from MVC .Net to Next.js with Typescript.
            </p>
            <p>
            Add structure code of the new revamp using Next.js with Typescript, and e2e test using Cypress and unit test using React Testing Library with Jest, also installed ESlint for code standards and formatting.</p>
            <p>
            Review PRs of other team members.
            </p>
            <p className={Style.pink}>Technologies</p>
            <ul className={Style.technologies}>
                <li>Next.js</li>
                <li>React.js</li>
                <li>Cypress</li>
                <li>React Testing Library</li>
                <li>Jest</li>
                <li>Axios</li>
                <li>Git</li>
                <li>Tailwind</li>
                <li>Sass</li>
                <li>Azure Devops</li>
            </ul>
        </>;
    }

    function appcorpText() {
        return <>
            <p><span className={Style.pink}>abanoub $</span> cd experience/appcorp</p>
            <p><span className={Style.pink}>experience/appcorp <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <p>
                <a
                    href="https://terkwaz.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Appcorp for value added services
                </a>
            </p>
            <p>
                Senior full-stack web developer
            </p>
            <p>
                06/2021 – 03/2023
            </p>
            <p>
                Senior front-end web developer
            </p>
            <p>
                11/2021 – 06/2022
            </p>
            <p className={Style.pink}> Managed to</p>
            <p>
                Revamp and maintain a sports website with live matches coverage <a
                    href="http://etisalatsports.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    EtisalatSports.com
                </a>, from CodeIgniter to React.js.
            </p>
            <p>
                Revamp and maintain a sports website with live matches coverage <a
                    href="http://twist-sports.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Twist-Sports.com
                </a>, and lead 3 frontend devs through this phase with adding unit tests and apply clean code standards.
            </p>
            <p>
                Revamp and maintain a netflix-clone website with streaming features using <a
                    href="https://bunny.net/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    bunny CDN
                </a>, using Next.js as a frontend technology and create the SQL database architecture and restful APIs using Django as a backend technology.
            </p>
            <p>
                Create a construction gallery website as a demo for a client named "Saraya el Alfy", using React.js as a frontend technology and create the mongo.db database architecture and restful APIs using Node.js and Express.js as a backend technology.       </p>
            <p>
                Create a POC of video streaming SFTP using Node.js and Express.js technologies.
            </p>
            <p>
                Create a POC of video streaming WebSockets using WebSocket API in a Node.js and Express.js app
            </p>
            <p className={Style.pink}>Technologies</p>
            <ul className={Style.technologies}>
                <li>React.js</li>
                <li>Redux</li>
                <li>React Router</li>
                <li>React Testing Library</li>
                <li>Axios</li>
                <li>Git</li>
                <li>Node.js</li>
                <li>Mongoose</li>
                <li>Bootstrap</li>
                <li>Reactstrap</li>
                <li>Sass</li>
                <li>Azure Devops</li>
            </ul>
        </>;
    }

    function terkwazText() {
        return <>
            <p><span className={Style.pink}>abanoub $</span> cd experience/terkwaz</p>
            <p><span className={Style.pink}>experience/terkwaz <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <p>
                <a
                    href="https://terkwaz.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Terkwaz Business Solutions
                </a>
            </p>
            <p>
                Front-end web developer
            </p>
            <p>
                04/2020 – 12/2021
            </p>
            <p className={Style.pink}> Managed to</p>
            <p>
                Create and maintain a +100 pages loan origination system located in USA, <a
                    href="https://blinklos.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Blinklos.com
                </a>, with other 3 front-end devs.
            </p>
            <p>
                Implement many features and reusable components and work with <a
                    href="https://blinklos.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    D3
                </a> in <a
                    href="https://blinklos.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Blinklos.com
                </a> application. 
            </p>
            <p>
                Fix some bugs in an online website, <a
                    href="https://hemma.sa/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    hemma.sa
                </a>, in production that I didn’t work on before.
            </p>
            <p>
                Create and maintain two projects, <a
                    href="https://gokwaz.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    gokwaz.com
                </a> and <a
                    href="https://makanat.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    makanat.com
                </a>, with other 2 front-end devs.
            </p>
            <p>
                Add a google maps API feature to <a
                    href="https://makanat.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    makanat.com
                </a>.
            </p>
            <p>
                Use git submodules to share it between mobile and web code.
            </p>
            <p>
                Work with ESlint rules on all projects.
            </p>
            <p className={Style.pink}>Technologies</p>
            <ul className={Style.technologies}>
                <li>React.js</li>
                <li>Redux</li>
                <li>React Router</li>
                <li>Typescript</li>
                <li>ESlint</li>
                <li>Jest and Enzyme</li>
                <li>Axios</li>
                <li>Git</li>
                <li>Node.js</li>
                <li>Mongoose</li>
                <li>Bootstrap</li>
                <li>Reactstrap</li>
                <li>Sass</li>
                <li>Scss</li>
                <li>Jira</li>
                <li>DigitalOcean</li>
                <li>Bitbucket</li>
            </ul>
        </>;
    }

    function dhsText() {
        return <>
            <p><span className={Style.pink}>abanoub $</span> cd experience/DHS</p>
            <p><span className={Style.pink}>experience/DHS <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <p>
                <a
                    href="https://www.dhsarabia.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    DHS Arabia
                </a>
            </p>
            <p>
                Front-end web developer
            </p>
            <p>
                01/2019 – 04/2020
            </p>
            <p className={Style.pink}> Managed to</p>
            <p>
                Fix many bugs of features implemented by other developers that left the company.
            </p>
            <p>
                Add new features like selecting a tooth number from a canvas. 
            </p>
            <p>
                Implement all features in Vanilla Javascript as requested by the manager
            </p>
            <p className={Style.pink}>Technologies</p>
            <ul className={Style.technologies}>
                <li>Vanilla Javascript</li>
                <li>CSS3</li>
                <li>Bootstrap</li>
                <li>HTML5</li>
                <li>Ajax</li>
                <li>Git</li>
                <li>MVC Core</li>
                <li>Blazor</li>
                <li>Azure</li>
            </ul>
        </>;
    }

    function projectsText() {
        return <>
            <p><span className={Style.pink}>abanoub $</span> cd experience/projects</p>
            <p><span className={Style.pink}>experience/projects <span
                className={Style.green}>(main)</span> $</span> ls</p>
            {
                projects.map((p) => (
                    <>
                        <p className={Style.pink}>
                            <a
                                href={p.link}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {p.linkText}
                            </a>
                            {' '}
                            ({p.location})
                        </p>
                        <p>
                            {p.desc}
                        </p>
                    </>
                ))
            }
        </>;
    }

    return (
        <div className={Style.main}>
            <Terminal text={rasanText()} />
            <Terminal text={appcorpText()} />
            <Terminal text={terkwazText()} />
            <Terminal text={dhsText()} />
            <Terminal text={projectsText()} />
        </div>
    )
}