import React from 'react';
import Style from './Experience.module.scss';
import Terminal from "./Terminal";

export default function Education() {

    function educationText() {
        return <>
            <p><span className={Style.pink}>abanoub $</span> cd education</p>
            <p><span className={Style.pink}>education <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <p className={Style.pink}> Bachelor's Degree</p>
            <ul className={Style.experience}>
                <li>2011 – 2016 ( 5 years study )</li>
                <li>Veterinary Medicine</li>
            </ul>
            <p className={Style.pink}> CS50 – Verified Certificates</p>
            <ul className={Style.experience}>
                <li>2021 - 2022</li>
                <li>CS50's Web Programming with Python and JavaScript from edX and HarvardX</li>
                <li /*className={Style.link}*/>
                    <a
                        href="https://courses.edx.org/certificates/642e953e5b5646f4a6a06d0caa3e9411"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        courses.edx.org/certificates/642e953e5b5646f4a6a06d0caa3e9411
                    </a>
                </li>
            </ul>
            <ul className={Style.experience}>
                <li>2019 - 2020</li>
                <li>CS50's Introduction to Computer Science from edX and HarvardX</li>
                <li /*className={Style.link}*/>
                    <a
                        href="https://courses.edx.org/certificates/5b9edaf1b60345f6818b883b8f1a08ad"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        courses.edx.org/certificates/5b9edaf1b60345f6818b883b8f1a08ad
                    </a>
                </li>
            </ul>
        </>;
    }

    return (
        <div className={Style.main}>
            <Terminal text={educationText()}/>
        </div>
    )
}