import React from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";


export default function About() {

    function aboutMeText() {
        return <>
            <p><span className={Style.pink}>abanoub $</span> touch abanoub </p>
            <p><span className={Style.pink}>abanoub <span className={Style.green}>(main)</span> $ </span>
                An international full-stack web developer, who was able to contribute, establish and maintain large projects (+100 pages) and add features to other mid-size projects in the USA, gulf area, and the middle east.
            </p>
            <p>
                Abanoub had a passion for the web since I was young and fell in love with front-end development at first and all the new technologies coming to the browsers.
            </p>
            <p>
                Also have built a lot of websites and applications for people and companies across the globe and helped many with troubleshooting / consultations about problems they have.
            </p>
            <p>
                After that he developed a passion for back-end web development, deployment, and artificial intelligence.
            </p>
            <p>
                He learned more about database architectures, data structure, algorithms and design patterns to be able to excel in the backend development
            </p>
        </>;
    }

    function skillsText() {
        return <>
            <p><span className={Style.pink}>abanoub $</span> cd about/skills</p>
            <p><span className={Style.pink}>about/skills <span
                className={Style.green}>(main)</span> $</span> ls</p>
            <p className={Style.pink}> Proficient With</p>
            <ul className={Style.skills}>
                <li>React.js</li>
                <li>Next.js</li>
                <li>JavaScript</li>
                <li>Python</li>
                <li>Redux</li>
                <li>Express.js</li>
                <li>Jest and Enzyme</li>
                <li>React Testing Enzyme</li>
                <li>Cypress</li>
                <li>ESlint</li>
                <li>Axios</li>
                <li>SQL</li>
                <li>ES6</li>
                <li>MVC Core View</li>
                <li>JQuery</li>
                <li>Ajax</li>
                <li>Sass</li>
                
                <li>Node.js</li>
                <li>Django</li>
                <li>Typescript</li>
                <li>React Router</li>
                <li>Flusk</li>
                <li>MongoDB</li>
                <li>Bootstrap</li>
                <li>Scss</li>
                <li>Ajax HTML5</li>
                <li>CSS3</li>
                <li>Webpack</li>
                <li>Git</li>
                <li>Jira</li>
                <li>Azure Devlops</li>
                <li>NPM</li>
                <li>Yarn</li>
                <li>Google Cloud Console</li>
                <li>DigitalOcean</li>
            </ul>
        </>;
    }

    return (
        <div className={Style.main}>
            <Terminal text={aboutMeText()}/>
            <Terminal text={skillsText()}/>
        </div>
    )
}