import React from 'react';
import './App.module.scss';
import BaseLayout from "./components/BaseLayout";

function App() {
  return (
          <div>
              <BaseLayout />
          </div>
  );
}



export default App;
