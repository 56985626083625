import React from 'react';
import CIcon from '@coreui/icons-react';
import { cibLeetcode } from '@coreui/icons';

function SocialIcon(props) {
    const {link, icon} = props;
    return (
        <a target="_blank"
           rel="noopener noreferrer" href={link}>
            {
                link.includes("leetcode")
                ? <CIcon icon={cibLeetcode} height={40} />
                : <i className={icon} aria-hidden="true"/>
            }
        </a>
    );
}

export default SocialIcon;